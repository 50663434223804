@import '../../../../toofaced_base/scss/theme-bootstrap';

.lc-service-drawer-blip {
  #{$rdirection}: 0;
  background: $color-white;
  bottom: calc(60px + 4em);
  box-shadow: 0 0 4px $color-black;
  display: none;
  position: fixed;
  z-index: 99999;
  width: 100%;
  @include breakpoint($medium-up) {
    #{$rdirection}: 5px;
    width: 400px;
  }
  h3.lc-service-drawer-blip__header-text {
    color: $color-light-black;
  }
  &__header-container {
    background: $color-pink-3;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }
  .device-mobile &__header-text,
  &__header-text {
    color: $color-white;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-transform: inherit;
  }
  &__button {
    background: $color-light-black;
    color: $color-pink-3;
    cursor: pointer;
    display: block;
    font-size: 14px;
    margin: 10px 0;
    padding: 15px 10px;
    text-align: center;
    width: 100%;
  }
  &__body-container {
    font-weight: bold;
    padding: 15px;
    background: $color-pink-2;
    border-top: 1px solid $color-pink-1;
    a:hover {
      color: $color-white;
    }
  }
  &__logo {
    .logo-toofaced {
      background: url(/media/export/cms/standard-logo.png) no-repeat;
      margin: 0 16px 0 0;
      background-position: center;
      background-size: 100%;
      height: 27px;
      width: 100px;
      display: block;
    }  
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @include breakpoint($medium-up) {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }

  .LPMcontainer {
    visibility: hidden;
  }
}

.rounded-live-chat-button {
  #{$rdirection}: 5px;
  align-items: center;
  background-color: $color-black;
  border: 2px solid $color-black;
  border-radius: 2em;
  bottom: 70px;
  color: $color-pink-3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1em;
  position: fixed;
  font-family: $font--proxima-nova;
  font-size: 15px;
  z-index: 100;
  @include breakpoint($medium-up) {
    bottom: 60px;
    z-index: 9999999;
  }
  &__down-caret {
    border-#{$rdirection}: 2px solid $color-pink-3;
    border-bottom: 2px solid $color-pink-3;
    height: 0.5em;
    margin: 0 10px 5px;
    transform: rotate(45deg);
    width: 0.5em;
    color: $color-pink-3;
  }
}

#blip-chat-open-iframe {
  display: none! important;
}

#sticky_chat_link_id {
  display: none;
}

.viewcart {
  .rounded-live-chat-button {
    bottom: 100px;
  }
  .lc-service-drawer-blip {
    bottom: calc(100px + 4em);
  }
}

.page-product {
  &.page-product--waitlist {
    .rounded-live-chat-button {
      bottom: 210px;
    }
    .lc-service-drawer-blip {
      bottom: calc(210px + 4em);
    }
    &-shaded {
      .rounded-live-chat-button {
        bottom: 250px;
      }
      .lc-service-drawer-blip {
        bottom: calc(250px + 4em);
      }
    }
  }
  .rounded-live-chat-button {
    bottom: 150px;
  }
  .lc-service-drawer-blip {
    bottom: calc(150px + 4em);
  }
}

.page-product,
.viewcart {
  .rounded-live-chat-button {
    @include breakpoint($medium-up) {
      bottom: 60px;
    }
  }
  .lc-service-drawer-blip {
    @include breakpoint($medium-up) {
      bottom: calc(60px + 4em);
    }
  }
}